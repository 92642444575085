var render = function render(){var _vm=this,_c=_vm._self._c;return (!this.$can('Read', 'Note'))?_c('div',{staticStyle:{"align-items":"center","padding":"16px","text-align":"center"}},[_c('b-img',{staticStyle:{"scale":"0.8"},attrs:{"fluid":"","src":_vm.notAuthorized,"alt":"Error page"}}),_c('h1',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t('You dont have permission view this page')))])],1):_c('b-card',{attrs:{"id":"page--notes","no-body":""}},[_c('div',[_c('sidebar-form',{attrs:{"visible":_vm.showForm,"resource-id":_vm.resourceId},on:{"saved":function($event){return _vm.fetchList()},"close":_vm.onCloseForm}}),_c('div',{staticClass:"m-2 d-flex justify-content-between table-actions"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-1"},[_c('b-button',{class:{
              'icon-disabled': _vm.$can('Create', 'Note') === false,
            },attrs:{"id":"create-button","variant":"primary"},on:{"click":function($event){_vm.$can('Create', 'Note') && _vm.create()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('Create New')))])],1),_c('b-tooltip',{attrs:{"target":"create-button"}},[_vm._v(" "+_vm._s(_vm.$can('Create', 'Note') === true ? _vm.$t('Create') : _vm.$t('You dont have permission to create note'))+" ")])],1),_c('div',[_c('imex-actions-dropdown',{attrs:{"schema-name":_vm.Schema.CORE,"entity-type":_vm.Entity.NOTE}})],1)]),_c('div',[_c('prozess-search-input',{attrs:{"value":_vm.search,"placeholder":_vm.$t('Search')},on:{"input":_vm.handleSearch}})],1)]),(!_vm.customizeLoading)?_c('div',{staticClass:"m-2"},[_c('b-table',{ref:"refTable",staticClass:"table-responsive",attrs:{"busy":_vm.loading,"items":_vm.items,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":_vm.$t('No matching records found'),"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"sort-changed":_vm.handleSortChange},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"head()",fn:function({ label }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(label)))])]}},{key:"cell(notes)",fn:function({ item }){return [_c('div',{staticClass:"line-clamp-1"},[_vm._v(" "+_vm._s(_vm.markupToText(item.notes))+" ")])]}},{key:"cell()",fn:function(data){return [(_vm.isTitle(data))?_c('router-link',{staticClass:"line-clamp-1",attrs:{"to":{
              name: 'note-view',
              params: {
                id: data.item.uuidKey,
                tab: _vm.GenericTabs.OVERVIEW_360,
              },
            }}},[_vm._v(" "+_vm._s(data.field.key === 'note' ? _vm.markupToText(_vm.findValue(data)) : _vm.findValue(data))+" ")]):_c('span',{staticClass:"line-clamp-1",class:{ 'text-primary': data.field.key.includes('mobile') }},[_vm._v(" "+_vm._s(data.field.key === 'note' ? _vm.markupToText(_vm.findValue(data)) : _vm.findValue(data))+" ")])]}},{key:"cell(actions)",fn:function({ item }){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                'icon-disabled': _vm.$can('Update', 'Note') === false,
              },attrs:{"id":`edit-row-${item.uuidKey}-preview-icon`,"icon":"EditIcon","size":"16"},on:{"click":function($event){_vm.$can('Update', 'Note') ? _vm.edit(item.uuidKey) : null}}}),_c('b-tooltip',{attrs:{"placement":"left","title":_vm.$can('Update', 'Note') === true ? _vm.$t('Edit') : _vm.$t('You dont have permission to update note'),"target":`edit-row-${item.uuidKey}-preview-icon`}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                'icon-disabled': _vm.$can('Delete', 'Note') === false,
              },attrs:{"id":`delete-row-${item.uuidKey}-preview-icon`,"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.remove(item.uuidKey, item.version)}}}),_c('b-tooltip',{attrs:{"placement":"left","title":_vm.$can('Delete', 'Note') === true ? _vm.$t('Delete') : _vm.$t('You dont have permission to remove note'),"target":`delete-row-${item.uuidKey}-preview-icon`}})],1)]}}],null,false,1405964875)})],1):_vm._e(),(!_vm.loading)?_c('prozess-pagination',{staticClass:"mx-2 mb-2",class:{ 'd-none': !_vm.total },attrs:{"meta":_vm.dataMeta,"page":_vm.currentPage,"total":_vm.total,"size":_vm.perPage},on:{"change":_vm.handleCurrentPage}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }