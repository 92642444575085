<template>
  <b-card
    id="page--forms"
    no-body
  >
    <div>
      <CRMRelationshipForm
        :type="type"
        :resource-id="resourceId"
        :visible="showForm"
        @close="showForm = false"
        @saved="fetchList"
      />
      <div class="m-2 d-flex justify-content-between table-actions">
        <div class="d-flex">
          <div class="mr-1">
            <b-button
              :id="`create-${type}-button`"
              variant="primary"
              :class="{
                'icon-disabled': !canCreate,
              }"
              @click="canCreate && toggleModal()"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Create New') }}</span>
            </b-button>
            <b-tooltip :target="`create-${type}-button`">
              {{ canCreate ? $t('Create') : $t('no-action-permission') }}
            </b-tooltip>
          </div>
        </div>
      </div>

      <div class="m-2">
        <b-table
          ref="refTable"
          class="table-responsive"
          :busy="loading"
          :items="items"
          :fields="tableColumns"
          responsive
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>
          <!-- START: Actions Column -->
          <template #cell(actions)="{ item }">
            <div class="text-nowrap">
              <feather-icon
                :id="`edit-row-${item.uuid}-icon`"
                icon="EditIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': !canUpdate,
                }"
                @click="canUpdate && toggleModal(item.uuid)"
              />
              <b-tooltip
                placement="left"
                :title="canUpdate ? $t('Edit') : $t('no-action-permission')"
                :target="`edit-row-${item.uuid}-icon`"
              />
              <feather-icon
                :id="`delete-row-${item.uuid}-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': !canDelete,
                }"
                @click="canDelete && confirmRemoval(item)"
              />
              <b-tooltip
                placement="left"
                :title="canDelete ? $t('Delete') : $t('no-action-permission')"
                :target="`delete-row-${item.uuid}-icon`"
              />
            </div>
          </template>
          <!-- END: Actions Column -->
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
  </b-card>
</template>

<script>
import CRMRelationshipForm from '@/components/CRMRelationships/CRMRelationshipForm.vue'
import crmRelationshipService from '@/services/crmRelationship'
import listMixinFactory from '@/mixins/list'
import { swalConfirmDeleteOption } from '@/helpers/app'

export default {
  components: {
    CRMRelationshipForm,
  },

  mixins: [
    listMixinFactory({
      routeName: 'crm-relationship-settings',
      service: crmRelationshipService,

      tableColumns: [
        {
          key: 'name',
          label: 'Relationship',
          class: 'disabled',
        },
        { key: 'actions', class: 'text-center disabled' },
      ],
    }),
  ],

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showForm: false,
      routeParams: { tab: null },
    }
  },

  computed: {
    canCreate() {
      return this.$can('Create', 'CRM_Relationship')
    },

    canUpdate() {
      return this.$can('Update', 'CRM_Relationship')
    },

    canDelete() {
      return this.$can('Delete', 'CRM_Relationship')
    },
  },

  created() {
    this.routeParams.tab = this.type
  },

  methods: {
    confirmRemoval(item) {
      if (!this.canDelete) {
        return
      }

      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(async result => {
        if (result.value) {
          await this.$async(
            crmRelationshipService.destroy(item.uuid, {
              version: item.version,
            }),
          )

          this.fetchList()
        }
      })
    },

    async getAllMethod(query) {
      query.q = this.type
      return crmRelationshipService.getAll(query)
    },

    routeWatcher(params) {
      if (params.tab === this.type) {
        this.fetchList()
      }
    },

    toggleModal(resourceId = null) {
      this.resourceId = resourceId
      this.showForm = true
    },
  },
}
</script>
