var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"system-settings-card",attrs:{"no-body":""}},[_c('div',[_c('sidebar-form',{attrs:{"visible":_vm.showForm,"resource-id":_vm.resourceId},on:{"saved":function($event){return _vm.refreshData()},"close":_vm.onCloseForm,"field-form-close":_vm.onFieldFormClose}}),_c('custom-entity-view',{attrs:{"visible":_vm.showView,"resource-id":_vm.resourceId},on:{"close":function($event){_vm.showView = false}}}),_c('div',{staticClass:"m-2 d-flex justify-content-between table-actions"},[_c('b-button',{class:{
          'icon-disabled': !_vm.canCreate,
        },attrs:{"id":"create-button","variant":"primary"},on:{"click":function($event){_vm.canCreate && _vm.create()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('New Entity')))])],1),_c('b-tooltip',{attrs:{"target":"create-button"}},[_vm._v(" "+_vm._s(_vm.canCreate ? _vm.$t('Create') : _vm.$t('You dont have permission to create'))+" ")])],1),_c('div',{staticClass:"m-2"},[_c('b-table',{ref:"refTable",staticClass:"table-responsive",attrs:{"busy":_vm.loading,"items":_vm.items,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":_vm.$t('No matching records found'),"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"sort-changed":_vm.handleSortChange},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"head()",fn:function({ label }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(label)))])]}},{key:"cell(label)",fn:function({ item }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.label)+" ")])]}},{key:"cell(actions)",fn:function({ item }){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                'icon-disabled': !_vm.canUpdate,
              },attrs:{"id":`edit-row-${item.key}-preview-icon`,"icon":"EditIcon","size":"16"},on:{"click":function($event){return _vm.edit(item.key)}}}),_c('b-tooltip',{attrs:{"title":_vm.canUpdate ? _vm.$t('Edit') : _vm.$t('You dont have permission to update'),"target":`edit-row-${item.key}-preview-icon`,"placement":"left"}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                'icon-disabled': !_vm.canDelete,
              },attrs:{"id":`delete-row-${item.id}-preview-icon`,"icon":"TrashIcon","size":"16"},on:{"click":function($event){_vm.canDelete && _vm.remove(item)}}}),_c('b-tooltip',{attrs:{"title":_vm.canDelete ? _vm.$t('Remove') : _vm.$t('You dont have permission to remove'),"target":`delete-row-${item.id}-preview-icon`,"placement":"left"}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",attrs:{"id":`view-row-${item.id}-preview-icon`,"icon":"EyeIcon","size":"16"},on:{"click":function($event){return _vm.view(item.key)}}}),_c('b-tooltip',{attrs:{"title":_vm.$t('View'),"target":`view-row-${item.id}-preview-icon`,"placement":"left"}})],1)]}}])})],1),(!_vm.loading)?_c('prozess-pagination',{staticClass:"mx-2 mb-2",class:{ 'd-none': !_vm.total },attrs:{"meta":_vm.dataMeta,"page":_vm.currentPage,"total":_vm.total,"size":_vm.perPage},on:{"change":_vm.handleCurrentPage}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }