<template>
  <b-card
    v-if="!visible"
    no-body
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-button
            id="create-button"
            variant="primary"
            :class="{
              'icon-disabled': !hasCreateAccess,
            }"
            @click="hasCreateAccess && create()"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Create New') }}</span>
          </b-button>
          <b-tooltip target="create-button">
            {{ hasCreateAccess ? $t('Create') : $t('no-action-permission') }}
          </b-tooltip>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex justify-content-end">
            <div>
              <prozess-search-input
                :value="search"
                :placeholder="$t('Search')"
                @input="handleSearch"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="m-2">
      <b-table
        ref="refTable"
        :busy="loading"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('No matching records found')"
        class="table-responsive"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        @sort-changed="handleSortChange"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #head()="{ label }">
          <span style="white-space: nowrap">{{ $t(label) }}</span>
        </template>

        <template #cell(name)="{ item }">
          <span
            class="text-bold text-primary cursor-pointer"
            @click="viewItem(item.uuidKey)"
          >{{ item.name }}
          </span>
        </template>

        <!-- START: Actions Column -->
        <template #cell(actions)="{ item }">
          <div class="text-nowrap">
            <feather-icon
              :id="`edit-row-${item.uuidKey}-preview-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1 cursor-pointer"
              :class="{
                'icon-disabled': !hasUpdateAccess,
              }"
              @click="hasUpdateAccess && editDataSource(item.uuidKey)"
            />

            <b-tooltip
              placement="left"
              :title="hasUpdateAccess ? $t('Edit') : $t('no-action-permission')"
              :target="`edit-row-${item.uuidKey}-preview-icon`"
            />
            <b-tooltip
              placement="left"
              :title="$t('Edit')"
              :target="`edit-row-${item.uuidKey}-preview-icon`"
            />
            <feather-icon
              :id="`delete-row-${item.uuidKey}-preview-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1 cursor-pointer"
              :class="{
                'icon-disabled': !hasDeleteAccess,
              }"
              @click="hasDeleteAccess && handleDelete(item.uuidKey, item.version)"
            />
            <b-tooltip
              placement="left"
              :title="hasDeleteAccess ? $t('Delete') : $t('no-action-permission')"
              :target="`delete-row-${item.uuidKey}-preview-icon`"
            />
          </div>
        </template>
      </b-table>
    </div>
    <!-- START: Pagination -->
    <prozess-pagination
      v-if="!loading"
      class="mx-2 mb-2"
      :class="{ 'd-none': !total }"
      :meta="dataMeta"
      :page="currentPage"
      :total="total"
      :size="perPage"
      @change="handleCurrentPage"
    />
    <!-- END: Pagination -->

    <!-- FORM -->
  </b-card>
  <data-source-form
    v-else
    :id="id"
    :view-only="viewOnly"
    @editMode="editDataSource(id)"
  />
</template>

<script>
import { swalConfirmDeleteOption } from '@/helpers/app'
import { mapState, mapMutations } from 'vuex'
import { dashboardService } from '@/services/dashboard'
import listMixinFactory from '@/mixins/list'
import DataSourceForm from '@/views/pages/SystemSettings/DashboardWidgets/Tabs/DataSourceForm.vue'

export default {
  name: 'DataSourceList',
  components: { DataSourceForm },
  mixins: [
    listMixinFactory({
      routeName: 'dashboard-widget',
      service: dashboardService('datasource'),
      tableColumns: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        { key: 'actions', class: 'text-center disabled' },
      ],

      searchField: 'name',
      sort: {
        name: 'name',
        label: 'label',
      },
    }),
  ],

  data() {
    return {
      viewOnly: false,
    }
  },
  computed: {
    ...mapState({
      visible: state => state.dashboard.dataSourceFormVisible,
      id: ({ dashboard }) => dashboard.id,
    }),
    hasCreateAccess() {
        return this.$can('Create', 'Data_Source')
      },
    hasUpdateAccess() {
        return this.$can('Update', 'Data_Source')
      },
    hasDeleteAccess() {
        return this.$can('Delete', 'Data_Source')
      },
  },

  watch: {
    visible(value) {
      if (!value) this.fetchList()
    },
  },

  beforeDestroy() {
    this.showDataSourceForm(false)
  },

  mounted() {
    this.search = ''
  },
  methods: {
    ...mapMutations({
      showDataSourceForm: 'dashboard/SET_DATASOURCE_FORM_VISIBILITY',
      setId: 'dashboard/SET_ID',
    }),
    create() {
      this.setId(null)
      this.showDataSourceForm(true)
    },

    viewItem(id) {
      this.viewOnly = true
      this.setId(id)
      this.showDataSourceForm(true)
    },

    editDataSource(id) {
      this.viewOnly = false
      this.setId(id)
      this.showDataSourceForm(true)
    },

    handleDelete(id, version) {
      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(async result => {
        if (result.value) {
          this.deleteResource(id, version)
        }
      })
    },
  },
}
</script>

<style></style>
