<template>
  <div id="dashboard-widget--index">
    <b-tabs
      class="tabs--inner"
      pills
      :value="tabs.indexOf(this.$route.params.tab)"
      @input="handleTabChange"
      >

      <b-tab :title="$t('Dashboards')" active  v-if="this.$can('Read', 'Dashboard') || this.$can('Create', 'Dashboard')">
        <b-card-text>
          <DashboardList v-if="this.$route.params.tab==='dashboards'"/>
        </b-card-text>
      </b-tab>

      <b-tab :title="$t('Widgets')" v-if="this.$can('Read', 'Widget') || this.$can('Create', 'Widget')">
          <b-card-text>
              <WidgetList v-if="this.$route.params.tab==='widgets'"/>
          </b-card-text>
      </b-tab>
      <b-tab :title="$t('Data Sources')"  v-if="this.$can('Read', 'Data_Source') || this.$can('Create', 'Data_Source')">
          <b-card-text>
              <DataSourceList v-if="this.$route.params.tab==='data-sources'"/>
          </b-card-text>
      </b-tab>

      <b-tab :title="$t('Advanced Data Sources')"  v-if="this.$can('Read', 'Advanced_Data_Source') || this.$can('Create', 'Advanced_Data_Source')">
          <b-card-text>
              <DataSourceAdvancedList v-if="this.$route.params.tab==='adv-data-sources'"/>
          </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import WidgetList from '@/views/pages/SystemSettings/DashboardWidgets/Tabs/WidgetList.vue'
import DataSourceList from '@/views/pages/SystemSettings/DashboardWidgets/Tabs/DataSourceList.vue'
import DataSourceAdvancedList from '@/views/pages/SystemSettings/DashboardWidgets/Tabs/DatasourcesAdvanced/DataSourceAdvancedList.vue'
import DashboardList from '@/views/pages/SystemSettings/DashboardWidgets/Tabs/DashboardList.vue'

export default {
  name: 'DashboardWidgetIndex',
  components: { WidgetList, DataSourceList, DashboardList, DataSourceAdvancedList },
  data() {
    return {
      // tabs: ['dashboards', 'widgets', 'data-sources', 'adv-data-sources'],
      defaultTab: 'dashboards',
    }
  },
  computed: {
    activeTab() {
      return this.$route.params.tab || this.defaultTab
    },
    tabs() {
      const tabz = []
      if (this.$can('Read', 'Dashboard') || this.$can('Create', 'Dashboard')) {
        tabz.push('dashboards')
      }
      if (this.$can('Read', 'Widget') || this.$can('Create', 'Widget')) {
        tabz.push('widgets')
      }
      if (this.$can('Read', 'Data_Source') || this.$can('Create', 'Data_Source')) {
        tabz.push('data-sources')
      }
      if (this.$can('Read', 'Advanced_Data_Source') || this.$can('Create', 'Advanced_Data_Source')) {
        tabz.push('adv-data-sources')
      }
      return tabz
    },
  },
  methods: {
    handleTabChange(index) {
      console.log('handleTabChange', index)
      this.$router.push({
        name: 'dashboard-widget',
        params: {
          tab: this.tabs[index],
        },
      })
    },
  },
}
</script>
