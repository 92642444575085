<template>
  <b-card class="prozess-digital-signature">
    <form id="formSidebar" @submit.prevent="save">
      <h2>
        {{ $t('Digital Signature') }}
      </h2>
      <p class="mb-4" v-if="hasReadOnlyPermission">{{ $t('You only have read-only permission to this page') }}</p>
      <div v-if="loading" class="d-flex justify-content-center">
        <b-spinner />
      </div>
      <div
        v-if="!loading"
        class="prozess-digital-signature__settings d-flex mt-1"
      >
        <div class="prozess-digital-signature__column w-100">
          <div
            class="prozess-digital-signature__signant d-flex justify-content-between align-items-center p-1"
          >
            <div class="d-flex align-items-center">
              <span>{{ $t('Signant') }}</span>
              <div
                class="prozess-digital-signature__signant-question-mark"
                @click="handleSignantLink"
              >
                ?
              </div>
            </div>
            <b-form-checkbox
              switch
              :disabled="!hasSystemSettingsActions"
              inline
              class="toggle-with-disable-class"
              :checked="isSignantEnabled"
              @input="
                (value) =>
                  setIsSignantEnabled({
                    hasDistributorIdAndAccessCode,
                    value,
                  })
              "
            />
          </div>
          <div v-if="isSignantEnabled">
            <prozess-input
              v-model="configuration.distributorId"
              :disabled="!isEditMode"
              class="mt-1"
              :placeholder="$t('DistributorID')"
              icon="HashIcon"
              field="distributorId"
              name="distributorId"
              :error="$hasError('configuration.distributorId')"
            />
            <prozess-input
              v-model="configuration.accessCode"
              :disabled="!isEditMode"
              class="mt-1"
              :placeholder="$t('AccessCode')"
              icon="HashIcon"
              field="accessCode"
              name="accessCode"
              :error="$hasError('configuration.accessCode')"
            />
            <prozess-input
              v-model="configuration.distributorSystemId"
              :disabled="!isEditMode"
              class="mt-1"
              :placeholder="$t('DistributorSystemID')"
              icon="HashIcon"
              field="distributorSystemId"
              name="distributorSystemId"
              :error="$hasError('configuration.distributorSystemId')"
            />
          </div>
        </div>
        <div class="prozess-digital-signature__column">
          <div v-if="isSignantEnabled">
            <div>
              <prozess-field-wrapper
                v-show="!defaults.useDocumentDescriptionAsRequestSignature"
                :error="$hasError('defaults.defaultDescription')"
              >
                <b-form-textarea
                  v-model="defaults.defaultDescription"
                  :disabled="!isEditMode"
                  :placeholder="$t('Description')"
                  rows="3"
                />
              </prozess-field-wrapper>
              <div
                class="prozess-digital-signature__document-default mb-2 d-flex align-items-center"
              >
                <b-form-checkbox
                  v-model="defaults.useDocumentDescriptionAsRequestSignature"
                  class="toggle-with-disable-class"
                  :disabled="!isEditMode || !hasSystemSettingsActions"
                  switch
                  inline
                />
                <span>{{ $t('Use document description as default') }}</span>
              </div>
            </div>
            <div>
              <prozess-input
                v-model="defaults.maxActiveDays"
                type="number"
                :disabled="!isEditMode"
                class="mt-1"
                :placeholder="$t('Default active days')"
                icon="ActivityIcon"
                field="maxActiveDays"
                name="maxActiveDays"
                :error="$hasError('defaults.maxActiveDays')"
                hint-position="left"
                :hint="
                  $t(
                    'Default number of days that the signature request is active'
                  ) + '(max 364)'
                "
              />
              <prozess-input
                v-model="defaults.maxDaysBeforeDeletion"
                type="number"
                :disabled="!isEditMode"
                class="mt-1"
                :placeholder="$t('Default deleted days')"
                icon="XCircleIcon"
                field="maxDaysBeforeDeletion"
                name="maxDaysBeforeDeletion"
                :error="$hasError('defaults.maxDaysBeforeDeletion')"
                :hint="
                  $t(
                    'Default number of days until signature request is deleted'
                  ) + '(max 365)'
                "
                hint-position="left"
              />
              <user-search
                v-model="specificUser"
                :disabled="!isEditMode"
                :error="$hasError('defaults.specificUser')"
                :hint="$t('Leave blank to set the creator to admin')"
              />
              <prozess-input
                v-model="defaults.senderName"
                :disabled="!isEditMode"
                class="mt-1"
                :placeholder="$t('Sender name')"
                icon="UserIcon"
                field="senderName"
                name="senderName"
                :error="$hasError('defaults.senderName')"
                :hint="$t('The email sender name from signant')"
                hint-position="left"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="isSignantEnabled" class="d-flex justify-content-end">
        <b-button v-if="isEditMode" variant="outline-primary" class="mr-1" @click="cancel">
          {{ $t('Cancel') }}
        </b-button>
        <button
          class="btn btn-primary mr-1 d-flex justify-content-center align-items-center"
          type="submit"
          form="formSidebar"
          :disabled="saving || !hasSystemSettingsActions"
        >
          <b-spinner v-if="saving" small class="mr-1" />
          <span>{{ $t(saveLabel) }}</span>
        </button>
      </div>
    </form>
  </b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import signatureService from '@/services/signature'
import UserSearch from '@/views/shared/UserSearch.vue'

const defaultConfiguration = {
  distributorId: null,
  accessCode: null,
  distributorSystemId: null,
}

export default {
  components: {
    UserSearch,
  },
  data() {
    return {
      showMessage: false,
      saving: false,
      loading: false,
      isEditMode: false,
      originalData: {},
      configuration: defaultConfiguration,
      defaults: {
        useDocumentDescriptionAsRequestSignature: true,
        defaultDescription: '',
        maxActiveDays: 0,
        maxDaysBeforeDeletion: 0,
        specificUser: null,
        senderName: '',
      },
      errors: [],
      specificUser: null,
      test: false,
    }
  },
  computed: {
    ...mapState('signant', ['isSignantEnabled']),
    saveLabel() {
      return this.isEditMode ? 'Save' : 'Edit'
    },
    hasDistributorIdAndAccessCode() {
      const { distributorId, accessCode } = this.configuration
      return distributorId !== null && accessCode !== null
    },
    hasReadOnlyPermission() {
      return this.hasSystemSettingsActions || (this.$can('Read', 'Signature') && !this.$can('Create', 'Signature') && !this.$can('Delete', 'Signature'))
    },
    hasUpdateAccess() {
        return this.$can('Update', 'Signature')
      },
    hasDeleteAccess() {
        return this.$can('Delete', 'Signature')
      },
  },
  created() {
    this.getInitialData()
  },
  methods: {
    ...mapActions({
      setIsSignantEnabled: 'signant/setIsSignantEnabled',
    }),
    cancel(e) {
      e.preventDefault()
      this.isEditMode = false
      this.errors = []
      this.configuration = JSON.parse(
        JSON.stringify(this.originalData.configuration),
      )
      this.defaults = JSON.parse(JSON.stringify(this.originalData.defaults))
      this.specificUser = JSON.parse(
        JSON.stringify(this.originalData.specificUser),
      )
    },
    prepareData() {
      const data = {}
      this.defaults.specificUser = this.$lodash.get(
        this.specificUser,
        'value.userUuid',
        null,
      )
      data.defaults = this.defaults
      data.configuration = this.configuration
      return data
    },
    async validate() {
      this.errors = []
      const yup = this.$yup

      const schema = yup.object().shape({
        configuration: yup.object().shape({
          distributorId: yup
            .string()
            .required()
            .nullable()
            .max(100, 'Max of 100'),
          accessCode: yup
            .string()
            .required()
            .nullable()
            .max(100, 'Max of 100'),
          distributorSystemId: yup
            .string()
            .required()
            .nullable()
            .max(100, 'Max of 100'),
        }),
        defaults: yup.object().shape({
          useDocumentDescriptionAsRequestSignature: yup.boolean(),
          defaultDescription: yup
            .string()
            .nullable()
            .when('useDocumentDescriptionAsRequestSignature', {
              is: false,
              then: yup.string().required(),
            })
            .max(100, 'Max of 100'),
          maxActiveDays: yup
            .number()
            .nullable()
            .required()
            .typeError('Required')
            .min(1, 'Minimum of 1')
            .max(365, 'Max of 365'),
          maxDaysBeforeDeletion: yup
            .number()
            .nullable()
            .required()
            .typeError('Required')
            .min(1, 'Minimum of 1')
            .max(365, 'Max of 365'),
          specificUser: yup.string().nullable(),
          senderName: yup
            .string()
            .required()
            .nullable(),
        }),
      })

      await schema
        .validate(this.prepareData(), {
          abortEarly: false,
        })
        .catch(err => {
          this.errors = err.inner.map(e => ({
            field: e.path,
            defaultMessage: e.message,
          }))
        })
    },
    save() {
      if (this.isEditMode === false) {
        this.isEditMode = true
        return
      }

      setTimeout(async () => {
        // delay saving to give way to trimming of input fields....
        this.defaults.defaultDescription = this.defaults.defaultDescription
          ? this.defaults.defaultDescription.trim()
          : ''
        await this.validate()
        if (this.errors.length > 0) return

        this.saving = true
        signatureService
          .saveConfigAndDefaults(this.prepareData())
          .then(res => {
            this.isEditMode = false
          })
          .catch(err => {
            let { errors } = err.response.data

            errors = errors.map(error => {
              if (
                error.defaultMessage
                === "field 'maxDaysBeforeDeletion' must be greater than field 'maxActiveDays'"
              ) {
                error.field = 'defaults.maxDaysBeforeDeletion'
              }
              return error
            })

            this.errors = errors
          })
          .finally(() => {
            this.saving = false
          })
      }, 200)
    },
    handleSignantLink() {
      window.open(
        'https://devdoc.signant.eu/download/request_signature v1.0.7.pdf',
        '_blank',
      )
    },
    async getInitialData() {
      try {
        this.loading = true
        const { data } = await signatureService.getState()
        const { configuration, globalDefaults } = data
        this.configuration = configuration
        this.defaults = globalDefaults
        const user = this.$lodash.get(this.defaults, 'defaultAdmin', null)

        if (user) {
          user.userUuid = user.uuidKey
          this.specificUser = {
            isNew: false,
            label: `${user.firstName} ${user.lastName}`,
            value: user,
          }
        }

        this.originalData = JSON.parse(
          JSON.stringify({
            configuration: this.configuration,
            defaults: this.defaults,
            specificUser: this.specificUser,
          }),
        )
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@/assets/scss/master-variables.scss';

.prozess-digital-signature {
  &__column {
    flex: 1;
    margin-right: 1rem;
  }

  &__signant {
    background: var(--colour--body-bg);
    border-radius: 8px;

    .custom-control {
      margin-right: 0;
    }
  }

  &__signant-question-mark {
    background: var(--colour--secondary);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    cursor: pointer;
    user-select: none;
    color: $colour--white;
  }

  &__document-default {
    label.custom-control-label {
      margin-left: 0 !important;
    }
    span {
      font-weight: bold;
    }
  }
}
</style>
