<template>
    <div v-if="!this.$can('Read', 'CRM')" style="align-items: center;padding: 16px; text-align: center;">
      <!-- <p class="">Oops! 😖 You cannot access the requested URL.</p> -->
      <b-img
        style="scale: 0.8;"
          fluid
          :src="notAuthorized"
          alt="Error page"
        />
        <h1 class="mt-2">{{ $t('You dont have permission view this page') }}</h1>
    </div>
  <b-card
    v-else
    no-body
    class="system-settings-card"
  >
    <div>
      <sidebar-form
        :visible="showForm"
        :resource-id="resourceId"
        :status-options="statusOptions"
        @saved="fetchList()"
        @close="onCloseForm"
      />
      <b-row
        class="m-2"
        align-h="between"
        align-v="baseline"
      >
        <b-col
          cols="13"
          lg="6"
          style="padding: 0"
        >
          <div class="d-flex" style="width: fit-content">
            <div class="mr-1">
              <b-button
                id="create-button"
                variant="primary"
                :class="{ 'icon-disabled': $can('Create', 'CRM') === false }"
                style="min-width: 128px; max-height: 35px; padding: 10px 10px"
                @click="$can('Create', 'CRM') && create()"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('Create New') }}</span>
              </b-button>
              <b-tooltip target="create-button">
                {{ $can('Create', 'CRM') === true ? $t('Create') : $t('You dont have permission to create contact') }}
              </b-tooltip>
            </div>
            <div>
              <imex-actions-dropdown
                :schema-name="Schema.CRM"
                :entity-type="Entity.CONTACT"
              />
            </div>
          </div>
        </b-col>
        <b-col
          cols="10"
          lg="6"
          style="padding: 0; padding-left: 40px"
        >
          <div class="row-flex-align-start row-flex-justify-end tw-mt-4">
            <prozess-search-input
              class="tw-mr-4"
              :value="search"
              :placeholder="$t('Search')"
              @input="handleSearch"
            />

            <status-filter
              ref="statusFilter"
              style="width: 200px"
              :value="statusId"
              :status-options="statusOptions"
              @statusSelected="handleStatusFilter"
            />

            <date-range-filter
              ref="dateRangeFilter"
              class="tw-ml-4"
              style="width: 200px; padding-top: 1px;"
              @applyDateFilter="applyDateFilter"
            />
          </div>
        </b-col>
      </b-row>
      <div class="m-2">
        <user-group-filter
          ref="userGroupFilter"
          @handleUserFilter="handleUserFilter"
          @handleGroupFilter="handleGroupFilter"
          @handleContactPersonFilter="handleContactPersonFilter"
          @handleFilterByCurrentUser="handleFilterByCurrentUser"
        />
      </div>
      <div class="m-2">
        <filter-input
          :filter-options="filterOptions"
          @applyFIlter="applyFIlter"
        />
      </div>
      <div
        v-if="!customizeLoading"
        class="m-2"
      >
        <b-table
          ref="refTable"
          :busy="loading"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          class="table-responsive"
          no-local-sorting
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="handleSortChange"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>

          <template #cell(mobileNumber)="{ item }">
            <span class="text-nowrap text-primary">
              {{ item.mobileNumber }}
            </span>
          </template>

          <template #cell(statusName)="{ item }">
            <span>{{ item.statusName }}</span>
          </template>

          <!-- START: for customizable fields -->

          <template #cell()="data">
            <router-link
              v-if="isTitle(data)"
              class="text-nowrap text-primary"
              :to="{
                name: 'contact-view',
                params: {
                  idFeed: data.item.id,
                  id: data.item.uuidKey,
                  tab: GenericTabs.OVERVIEW_360,
                },
              }"
            >
              {{ findValue(data) }}
            </router-link>
            <span
              v-else
              :id="`tooltip-${data.index}-${data.field.key}`"
              style="max-width: 200px"
              class="d-block"
              :class="{ 'text-primary': data.field.key.includes('mobile'), 'text-truncate': data.field.type === 'LONGTEXT' }"
            >
              {{ findValue(data) }}
            </span>
            <b-tooltip
              v-if="data.field.type === 'LONGTEXT'"
              :target="`tooltip-${data.index}-${data.field.key}`"
              :title="findValue(data)"
              placement="down"
            />
          </template>

          <!-- END: for customizable fields -->

          <!-- Column: Actions -->
          <template #cell(actions)="{ item }">
            <div class="text-nowrap">
              <feather-icon
                :id="`edit-row-${item.uuidKey}-preview-icon`"
                icon="EditIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': $can('Update', 'CRM') === false,
                }"
                @click="$can('Update', 'CRM') ? edit(item.uuidKey) : null"
              />
              <b-tooltip
                placement="left"
                :title="$can('Update', 'CRM') === true ? $t('Edit') : $t('You dont have permission to update contacts')"
                :target="`edit-row-${item.uuidKey}-preview-icon`"
              />
              <feather-icon
                :id="`delete-row-${item.uuidKey}-preview-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                :class="{
                  'icon-disabled': $can('Delete', 'CRM') === false,
                }"
                @click="remove(item.uuidKey, item.version)"
              />
              <b-tooltip
                placement="left"
                :title="$can('Delete', 'CRM') === true ? $t('Deactivate') : $t('You dont have permission to deactivate contacts')"
                :target="`delete-row-${item.uuidKey}-preview-icon`"
              />
            </div>
          </template>
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
  </b-card>
</template>

<script>
import FilterInput from '@/components/Lists/FilterInput.vue'
import { Schema, Entity, GenericTabs } from '@/constants/app'
import contactService from '@/services/contact'
import ImexActionsDropdown from '@/views/shared/Imex/ImexActionsDropdown.vue'
import listMixinFactory from '@/mixins/list'
import SidebarForm from '@/components/Contact/ContactForm.vue'
import metastatusDropdownMixin from '@/mixins/metastatusDropdown'
import StatusFilter from '@/components/Lists/StatusFilter.vue'
import DateRangeFilter from '@/components/Lists/DateRangeFilter.vue'
import UserGroupFilter from '@/components/Lists/UserGroupFilter.vue'

export default {
  components: {
    SidebarForm,
    ImexActionsDropdown,
    FilterInput,
    StatusFilter,
    DateRangeFilter,
    UserGroupFilter,
  },
  mixins: [
    metastatusDropdownMixin,
    listMixinFactory({
      routeName: 'contacts',
      service: contactService,
      isCustomizable: true,
      listFormat: true,
      searchField: 'firstName',
      sort: {
        name: 'firstName',
      },
      tableColumns: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'emailAddress', label: 'Email', class: 'disabled' },
        { key: 'mobileNumber', label: 'Mobile', class: 'disabled' },
        { key: 'title', label: 'Job Title', class: 'text-center disabled' },
        {
          key: 'numberOfCompanies',
          label: 'Companies',
          class: 'text-center disabled',
        },
        { key: 'actions', class: 'text-center disabled' },
      ],
    }),
  ],
  data() {
    return {
      GenericTabs,
      Schema,
      Entity,
      notAuthorized: require('@/assets/images/pages/not-authorized.svg'),
    }
  },

  mounted() {
    this.getMetastatusInitialData()
  },
  methods: {
    create() {
      this.resourceId = null
      this.showForm = true
    },
    remove(id, version) {
      if (this.$can('Delete', 'CRM') === false) return
      this.$swal({
        title: this.$t('Warning'),
        text: this.$t('Are you sure you want to deactivate?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteResource(id, version)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
