var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.showForm)?_c('b-card',{staticClass:"system-settings-card",attrs:{"no-body":"","id":"page--forms"}},[_c('div',{staticClass:"m-2 d-flex justify-content-between table-actions"},[_c('b-button',{class:{
        'icon-disabled': !_vm.hasCreateAccess,
      },attrs:{"id":"create-button","variant":"primary"},on:{"click":function($event){_vm.hasCreateAccess && _vm.create()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('Create New')))])],1),_c('b-tooltip',{attrs:{"target":"create-button"}},[_vm._v(" "+_vm._s(_vm.hasCreateAccess ? _vm.$t('Create') : _vm.$t('no-action-permission'))+" ")]),_c('prozess-search-input',{attrs:{"value":_vm.search,"placeholder":_vm.$t('Search')},on:{"input":_vm.handleSearch}})],1),_c('div',{staticClass:"m-2"},[_c('b-table',{ref:"refTable",staticClass:"table-responsive",attrs:{"busy":_vm.loading,"items":_vm.items,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":_vm.$t('No matching records found'),"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"sort-changed":_vm.handleSortChange},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"head()",fn:function({ label }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(label)))])]}},{key:"cell(actions)",fn:function({ item }){return [_c('div',{staticClass:"row-flex-align-center row-flex-justify-center"},[_c('feather-icon',{staticClass:"cursor-pointer",class:{
              'icon-disabled': !_vm.hasUpdateAccess,
            },attrs:{"id":`edit-row-${item.uuidKey}-preview-icon`,"icon":"EditIcon","size":"16"},on:{"click":function($event){_vm.hasUpdateAccess && _vm.edit(item.uuidKey)}}}),_c('b-tooltip',{attrs:{"placement":"left","title":_vm.hasUpdateAccess ? _vm.$t('Edit') : _vm.$t('You dont have permission to update dashboard'),"target":`edit-row-${item.uuidKey}-preview-icon`}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
              'icon-disabled': !_vm.hasDeleteAccess,
            },attrs:{"id":`delete-row-${item.uuidKey}-preview-icon`,"icon":"TrashIcon","size":"16"},on:{"click":function($event){_vm.hasDeleteAccess && _vm.handleDelete(item.uuidKey, item.version)}}}),_c('b-tooltip',{attrs:{"placement":"left","title":_vm.hasDeleteAccess ? _vm.$t('Delete') : _vm.$t('You dont have permission to remove data source'),"target":`delete-row-${item.uuidKey}-preview-icon`}})],1)]}}],null,false,3819996057)})],1),(!_vm.loading)?_c('prozess-pagination',{staticClass:"mx-2 mb-2",class:{ 'd-none': !_vm.total },attrs:{"meta":_vm.dataMeta,"page":_vm.currentPage,"total":_vm.total,"size":_vm.perPage},on:{"change":_vm.handleCurrentPage}}):_vm._e()],1):_c('dashboard-form',{attrs:{"id":_vm.isEditMode ? _vm.resourceId : _vm.id,"view-only":_vm.viewOnly,"is-edit-mode":_vm.isEditMode,"show":_vm.showForm},on:{"editMode":function($event){return _vm.editDataSource(_vm.id)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }