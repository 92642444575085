<template>
  <div>
    <sidebar-form
      :visible="showForm"
      :resource-id="resourceId"
      :status-options="statusOptions"
      @saved="getDetails()"
      @close="onCloseForm"
    />
    <prozess-resource-view
      table-name="company"
      :resource="resource"
      :loading="loading"
      :fields="fields"
      :custom-fields="customFields"
      :status-options="statusOptions"
      :can-update="$can('Update', 'CRM')"
      @edit="$can('Update', 'CRM') ? edit(resource.uuid) : null"
      @relationshipsLoaded="handleRelationshipsLoaded"
    >
      <template slot="body">
        <prozess-tab-loader v-if="relationshipsLoading" />
        <b-tabs
          v-else
          content-class="mt-1"
          :value="tabs.indexOf(this.$route.params.tab)"
          @input="handleTabInput"
        >
          <b-tab>
            <template #title> <feather-icon icon="GridIcon" /> <p style="font-weight: 600; margin:0px;">{{ $t('360 Overview') }}</p></template>
            <EntityOverviewWidgets
              :resource="resource"
              :field-labels="fields"
              schema-key="crm"
              table-key="company"
            />
          </b-tab>
          <b-tab v-if="this.$can('Read', 'Dashboard')">
            <template #title> <feather-icon icon="TrendingUpIcon" /> <p style="font-weight: 600; margin:0px;">{{ $t('Dashboard') }}</p></template>
            <dashboard-tab />
          </b-tab>
          <b-tab>
            <template #title> <feather-icon icon="BriefcaseIcon" /><p style="font-weight: 600; margin:0px;">{{ $t('Companies') }}</p></template>
            <CRMRelationshipMappingList
              :type="Entity.COMPANY"
              :entity-id="$route.params.id"
              :resource-name="resource.name"
            />
          </b-tab>
          <b-tab>
            <template #title> <feather-icon icon="UsersIcon" /><p style="font-weight: 600; margin:0px;">{{ $t('Contacts') }}</p> </template>
            <contact-list :status-options="statusOptions" />
          </b-tab>
          <b-tab v-if="this.$can('Read', 'Document')">
            <template #title> <feather-icon icon="CheckSquareIcon" /><p style="font-weight: 600; margin:0px;">{{ $t('Documents') }}</p></template>
            <document-list
              entity-type="company"
              :entity-id="$route.params.id"
            />
          </b-tab>
          <b-tab>
            <template #title> <feather-icon icon="ListIcon" /><p style="font-weight: 600; margin:0px;">{{ $t('History') }}</p> </template>
            <b-overlay
              :show="loading"
              spinner-variant="primary"
              rounded="sm"
              variant="transparent"
              style="height: 100%"
              class="d-flex flex-column"
            >
              <div class="card resource-view__scrollable">
                <div class="card-body mb-5">
                  <prozess-resource-history
                    :history="items"
                    :loading="loading"
                    :history-pagination="{
                      currentPage,
                      total,
                      perPage,
                    }"
                    @handleCurrentPage="handleCurrentPage"
                  />
                </div>
              </div>
            </b-overlay>
          </b-tab>
          <b-tab v-if="this.$can('Read', 'Inquiry')" :title="$t('Inquiries')">
            <template #title> <feather-icon icon="HelpCircleIcon" /><p style="font-weight: 600; margin:0px;"> {{ $t('Inquiries') }}</p></template>
            <inquiries
              :entity-type="Entity.COMPANY"
              :entity-id="$route.params.id"
            />
          </b-tab>
          <b-tab v-if="this.$can('Read', 'Note')">
            <template #title> <feather-icon icon="ClipboardIcon" /><p style="font-weight: 600; margin:0px;">{{ $t('Notes') }}</p> <br /></template>
            <notes
              entity-type="company"
              :entity-id="$route.params.id"
            />
          </b-tab>
          <b-tab v-if="this.$can('Read', 'Todo')" :title="$t('To Do')">
            <template #title> <feather-icon icon="ListIcon" /><p style="font-weight: 600; margin:0px;"> {{ $t('To Do') }}</p></template>
            <todo
              :entity-type="Entity.COMPANY"
              :entity-id="$route.params.id"
            />
          </b-tab>
          <b-tab :title="$t('Sales')">
            <template #title><feather-icon icon="CircleIcon" /><p style="font-weight: 600; margin:0px;">{{ $t('Sales') }}</p></template>
            <Sales />
          </b-tab>
          <b-tab>
            <template #title><feather-icon icon="CircleIcon" /><p style="font-weight: 600; margin:0px;">{{ $t('Commissions') }}</p> <br /></template>
            <Commissions />
          </b-tab>
          <b-tab
            v-for="relationship of relationships"
            :key="relationship.key2"
            :title="relationship.label2"
          >
            <template #title> <feather-icon icon="CircleIcon" /> <p style="margin:0px; color:rgb(52, 52, 52)">{{ $t(relationship.label2) }}</p></template>
            <x-to-many-list
              owning-entity-type="company"
              :owning-entity-id="$route.params.id"
              :owning-schema="Schema.CRM"
              :related-entity-type="relationship.key2"
              :related-schema="relationship.schema2"
              :related-entity-id="relationship.id"
            />
          </b-tab>
        </b-tabs>
      </template>
    </prozess-resource-view>
  </div>
</template>

<script>
import { Schema, Entity, GenericTabs } from '@/constants/app'
import companyService from '@/services/company'
import listMixinFactory from '@/mixins/list'
import RootEvents from '@/constants/rootEvents'
import Notes from '@/views/pages/Notes/ListOnView/index.vue'
import SidebarForm from '@/components/Company/CompanyForm.vue'
import ContactList from '@/views/pages/Companies/ContactList.vue'
import DocumentList from '@/views/pages/Documents/ListOnView/index.vue'
import ProzessTabLoader from '@/@core/components/ProzessTabLoader.vue'
import XToManyList from '@/views/pages/CustomEntity/List/XToManyList.vue'
import Inquiries from '@/views/pages/Inquiries/ListOnView/index.vue'
import Todo from '@/views/pages/Todo/ListOnView/index.vue'
import CRMRelationshipMappingList from '@/components/CRMRelationships/CRMRelationshipMappingList.vue'
import EntityOverviewWidgets from '@/components/EntityOverview/EntityOverviewWidgets.vue'
import CustomFieldTypes from '@/constants/customFieldTypes'
import executeQueryFieldMixin from '@/mixins/executeQueryField'
import metastatusDropdownMixin from '@/mixins/metastatusDropdown'
import Commissions from '@/views/pages/Commissions/ListOnView/View.vue'
import Sales from '@/views/pages/Sales/Seller/ListOnView/index.vue'
import DashboardTab from '@/views/pages/DashboardTab.vue'

export default {
  components: {
    SidebarForm,
    ContactList,
    DocumentList,
    Notes,
    ProzessTabLoader,
    XToManyList,
    Inquiries,
    Todo,
    CRMRelationshipMappingList,
    EntityOverviewWidgets,
    Commissions,
    Sales,
    DashboardTab,
  },

  mixins: [
    metastatusDropdownMixin,
    listMixinFactory({
      service: companyService,
      isHistory: true,
    }),

    executeQueryFieldMixin({
      schemaKey: Schema.CRM,
      tableKey: Entity.COMPANY,
    }),
  ],

  data() {
    return {
      Entity,
      Schema,
      resource: {
        customFields: {},
      },
      history: [],
      loading: true,
      fields: {
        name: 'Company Name',
        companyNumber: 'Org No.',
        address: 'Address',
        zip: 'Zip',
        city: 'City',
        country: 'Country',
        description: 'Description',
        phone: 'Phone',
      },
      customFields: {},
      tabs: [GenericTabs.OVERVIEW_360, GenericTabs.DASHBOARD, GenericTabs.COMPANIES, GenericTabs.CONTACTS],
      relationships: [],
      relationshipsLoading: true,
    }
  },

  watch: {
    '$route.params.id': {
      handler() {
        this.getDetails()
        this.getMetastatusInitialData()
      },

      immediate: true,
    },
  },

  methods: {
    handleRelationshipsLoaded(value) {
      this.relationships = value.filter(item => item.schema2 !== 'crm')
      this.relationships.sort((a, b) => a.label2.toLowerCase().localeCompare(b.label2.toLowerCase()))
      this.tabs = [
        ...this.tabs,
        GenericTabs.DOCUMENTS,
        GenericTabs.HISTORY,
        GenericTabs.INQUIRIES,
        GenericTabs.NOTES,
        GenericTabs.TODO,
        GenericTabs.SALES,
        GenericTabs.COMMISSIONS,
        ...this.relationships.map(item => item.key2),
      ]
      if (!this.$can('Read', 'Dashboard')) {
        this.tabs = this.tabs.filter(item => item !== GenericTabs.DASHBOARD)
      }
      if (!this.$can('Read', 'Document')) {
        this.tabs = this.tabs.filter(item => item !== GenericTabs.DOCUMENT)
      }
      if (!this.$can('Read', 'Note')) {
        this.tabs = this.tabs.filter(item => item !== GenericTabs.NOTES)
      }
      if (!this.$can('Read', 'Inquiry')) {
        this.tabs = this.tabs.filter(item => item !== GenericTabs.INQUIRIES)
      }
      if (!this.$can('Read', 'Todo')) {
        this.tabs = this.tabs.filter(item => item !== GenericTabs.TODO)
      }
      this.relationshipsLoading = false
    },

    handleTabInput(index) {
      this.$router
        .push({
          name: 'company-view',
          params: {
            idFeed: this.$route.params.idFeed,
            id: this.$route.params.id,
            tab: this.tabs[index] || null,
          },
        })
        .catch(() => {})
    },

    getDetails() {
      return companyService.getOne(this.$route.params.id).then(res => {
        const sortedCustomFields = res.data.customFieldMetadata.sort((a, b) => b.favorite - a.favorite)

        this.customFields = sortedCustomFields.reduce((acc, field) => {
          acc[field.key] = field
          return acc
        }, {})

        const customFields = {
          ...sortedCustomFields.reduce((acc, customField) => {
            const result = res.data.customFieldData.find(data => customField.id === data.metadataId)

            // This is needed for prefilling query fields. See: src/mixins/executeQueryField.js
            if (customField.fieldType === CustomFieldTypes.Query) {
              this.queryFields.push(customField)
              this.$lodash.set(result, 'value', 'Querying...')
            }

            if (result) {
              acc[customField.key] = result.value
            }
            return acc
          }, {}),
        }

        this.resource = {
          ...res.data,
          customFields,
        }

        this.$lodash.invoke(this, 'prefillQueryFields')

        this.$root.$emit(RootEvents.SET_PAGE_TITLE, `${this.resource.name}`)
      })
    },
  },
}
</script>
